
import { Action } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormSubmit } from "@/components/forms";
import { bus } from "@/main";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { DocumentFolder, DocumentType, IndexDocumentPayload, DocumentAccess } from "@/store/modules/document.store";
import { Mandate, WorkStatus } from "@/store/modules/mandate.store";
import { Debounce } from "@/utils/vue-helpers";

enum SignMandateStatus {
    validateDocument = "VALIDATE_DOCUMENT",
    createDocument = "CREATE_DOCUMENT",
    linkDocument = "LINK_DOCUMENT",
    connectConnective = "CONNECT_WITH_CONNECTIVE",
    connectiveDraft = "CONNECTIVE_DRAFT",
    connectiveStakeholder = "CONNECTIVE_STAKEHOLDERS",
    connectiveSignAttempt = "CONNECTIVE_SIGNATTEMPT",
    connectiveRequestSignatures = "CONNECTIVE_REQUEST_SIGNATURES",
    success = "SUCCESS",
}

interface SignMandateModalOptions {
    activity_id: number;
    activity_name: string;
    mandate_id: number;
    name: string;
    type: WorkStatus;
}

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        Spinner: () => import("@/components/general/spinner.vue"),
        FormBase,
        FormSubmit,
    },
})
export default class SignMandateModal extends Mixins(HasFormErrors) {
    @Action("document/create") createDocument!: DocumentCreate;
    @Action("mandate/makeConnectiveDraft") makeConnectiveDraft!: (payload: { id: number; document_ids: number[] }) => Promise<Mandate>;
    @Action("mandate/attemptSign") attemptSign!: (payload: { id: number; actors: ConnectiveSignatureRequestActor[]; unknown_actors: boolean }) => Promise<Mandate>;
    @Action("mandate/requestF2FSigningUrl") requestF2FSigningUrl!: (payload: number) => Promise<string>;
    @Action("mandate/preview") previewMandate!: (payload: { mandate_id: number; type: WorkStatus }) => Promise<any>;
    @Action("temp-media/upload") uploadTempImage!: TempMediaUpload;

    @Ref() form!: FormClass;

    show = false;

    SignMandateStatus = SignMandateStatus;

    status: string | null = null;

    loading = false;

    options: SignMandateModalOptions = {
        activity_id: NaN,
        activity_name: "",
        mandate_id: NaN,
        name: "",
        type: WorkStatus.brokerage_agreement_inside_salesarea,
    };

    mounted() {
        bus.$off("show-sign-mandate-modal");
        bus.$on("show-sign-mandate-modal", (options: SignMandateModalOptions) => {
            this.show = true;
            this.options = { ...this.options, ...options };
            this.submit();
        });
    }

    @Debounce(500)
    async submit() {
        try {
            this.loading = true;

            this.status = SignMandateStatus.validateDocument;

            this.status = SignMandateStatus.createDocument;

            const document_ids = [];

            if (this.options.type === WorkStatus.brokerage_agreement_outside_salesarea) {
                const pc_document = await this.uploadFile(`Precontractuele checklist - ${this.options.activity_name}`, WorkStatus.pre_contractual_information_sales_mediation, DocumentType.precontractueleChecklist);
                document_ids.push(pc_document.id);
            }

            const document = await this.uploadFile(`${this.options.name} - ${this.options.activity_name}`, this.options.type, DocumentType.bemiddelingsOvereenkomst);
            document_ids.push(document.id);

            this.status = SignMandateStatus.linkDocument;

            this.status = SignMandateStatus.connectConnective;

            let mandate = await this.makeConnectiveDraft({ id: this.options.mandate_id, document_ids: document_ids });

            this.status = SignMandateStatus.connectiveSignAttempt;

            const unknown_actors = this.hasUnknownActors(mandate);
            mandate = await this.attemptSign({ id: this.options.mandate_id, actors: this.getStakeholders(mandate), unknown_actors: unknown_actors });

            this.status = SignMandateStatus.connectiveRequestSignatures;

            const f2fUrl = await this.requestF2FSigningUrl(this.options.mandate_id);

            this.status = SignMandateStatus.success;

            window.location.href = f2fUrl;
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            this.loading = false;
        }
    }

    private async createPreviewFile(name: string, type: WorkStatus) {
        const blob = await this.previewMandate({ mandate_id: this.options.mandate_id, type: type });

        return new File([blob], `${name}.pdf`);
    }

    private async uploadFile(name: string, type: WorkStatus, documentType: DocumentType) {
        const file: File = await this.createPreviewFile(name, type);

        if (!file) {
            throw new Error("Kon tijdelijke bestand niet uploaden");
        }

        const tempFile = await this.uploadTempImage({ file: file, name: name, uuid: this.uuidv4() });

        const document = await this.createDocument({
            name: name,
            meta: { folder: DocumentFolder.mediationAgreement, access: [DocumentAccess.internal] },
            type: documentType,
            activity_id: this.options.activity_id,
            media: [tempFile],
        });

        return document;
    }

    private uuidv4() {
        //@ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    }

    private hasUnknownActors(mandate: Mandate): boolean {
        let unknown_actors = false;

        if (mandate.details.hasOwnProperty("candidates") && Array.isArray(mandate.details!.candidates) && mandate.details.candidates.filter((c) => c.not_all_information_known).length > 0) {
            unknown_actors = true;
        }

        if (mandate.details.hasOwnProperty("ground_owner_company_candidates") && Array.isArray(mandate.details!.ground_owner_company_candidates) && mandate.details.ground_owner_company_candidates.filter((c) => c.not_all_information_known).length > 0) {
            unknown_actors = true;
        }

        if (mandate.details.hasOwnProperty("construction_owner_company_candidates") && Array.isArray(mandate.details!.construction_owner_company_candidates) && mandate.details.construction_owner_company_candidates.filter((c) => c.not_all_information_known).length > 0) {
            unknown_actors = true;
        }

        if (mandate.details.hasOwnProperty("company_candidates") && Array.isArray(mandate.details!.company_candidates) && mandate.details.company_candidates.filter((c) => c.not_all_information_known).length > 0) {
            unknown_actors = true;
        }

        return unknown_actors;
    }

    private getStakeholders(mandate: Mandate): ConnectiveSignatureRequestActor[] {
        let signers: any[] = [];
        if (mandate.details.hasOwnProperty("candidates") && Array.isArray(mandate.details!.candidates)) {
            signers = signers.concat(mandate.details.candidates.filter((c) => !c.not_all_information_known));
        }

        if (mandate.details.hasOwnProperty("ground_owner_company_candidates") && Array.isArray(mandate.details!.ground_owner_company_candidates)) {
            signers = signers.concat(mandate.details.ground_owner_company_candidates.filter((c) => !c.not_all_information_known));
        }

        if (mandate.details.hasOwnProperty("construction_owner_company_candidates") && Array.isArray(mandate.details!.construction_owner_company_candidates)) {
            signers = signers.concat(mandate.details.construction_owner_company_candidates.filter((c) => !c.not_all_information_known));
        }

        if (mandate.details.hasOwnProperty("company_candidates") && Array.isArray(mandate.details!.company_candidates)) {
            signers = signers.concat(mandate.details.company_candidates.filter((c) => !c.not_all_information_known));
        }

        return signers.map((candidate) => {
            return {
                type: "signer",
                language: "nl",
                firstname: candidate.first_name,
                lastname: candidate.last_name,
                birthdate: candidate.birth_date ?? null,
                email: candidate.email,
            };
        });
    }

    handleClose() {
        this.clearErrors();

        this.show = false;
    }
}
